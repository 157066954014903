import req from "../utilities/request-utility";
import { getLanguage } from "../actions/languageActions";
import { getPages } from "../actions/pagesActions";
import saveStateToLocalstorage from "../utilities/save-state-to-local-storage";

import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_FAILURE_HANDLED,
  UPDATE_USER_DATA,
  CHECK_TOKEN,
  CHECK_TOKEN_VALID,
  CHECK_TOKEN_INVALID,
  CHECK_TOKEN_SERVICE_UNAVAILABLE,
  RENEW_TOKEN_SUCCESS,
  RENEW_TOKEN_FAILURE,
  RENEW_PASSWORD_FAILED,
  RENEW_PASSWORD_SUCCESS,
  RENEW_PASSWORD_HANDLED,
  AD_SIGN_IN_FAILURE,
  AD_SIGN_IN_FAILURE_HANDLED,
} from "./actionTypes";
import axios from "axios";
import getAppName from "../utilities/get-app-name";

export function updateUserData(user) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_DATA,
      payload: user,
    });
  };
}

export function signInWithAdToken(token) {
  return (dispatch, getState) => {
    dispatch({ type: SIGN_IN });

    req()
      .post(`/auth/sign-in-with-ad-token/`, { token, appName: getAppName() })
      .then(({ data }) => {
        dispatch({
          type: SIGN_IN_SUCCESS,
          payload: {
            token: data.token,
            user: data.user,
          },
        });

        dispatch(getLanguage());
        dispatch(getPages());
        // Save state (wait for eventloop to not get stale state)
        setTimeout(() => saveStateToLocalstorage(getState()), 0);
      })
      .catch(() => {
        dispatch({
          type: SIGN_IN_FAILURE,
        });
        dispatch({
          type: AD_SIGN_IN_FAILURE,
        });
      });
  };
}

export function adSignInErrorHandled() {
  return {
    type: AD_SIGN_IN_FAILURE_HANDLED,
  };
}

export function signIn(credentials) {
  return (dispatch, getState) => {
    dispatch({ type: SIGN_IN });

    let state = getState();

    // Create login credentials (password and appId will always be the same, login identifier (email or phone) can vary)
    let localCredentials = {
      password: credentials.password,
      applicationId: state.appConfig.appId,
    };

    if (credentials.phone) localCredentials.phone = credentials.phone;
    if (credentials.email) localCredentials.email = credentials.email;

    req()
      .post("/auth/sign-in", localCredentials)
      .then(({ data }) => {
        dispatch({
          type: SIGN_IN_SUCCESS,
          payload: {
            token: data.token,
            user: data.user,
          },
        });
        dispatch(getLanguage());
        dispatch(getPages());
        // Save state (wait for eventloop to not get stale state)
        setTimeout(() => saveStateToLocalstorage(getState()), 0);
      })
      .catch((error) => {
        dispatch({
          type: SIGN_IN_FAILURE,
          payload: error.response.data,
        });
      });
  };
}

export function signInFailureHandled() {
  return {
    type: SIGN_IN_FAILURE_HANDLED,
  };
}

export function checkToken() {
  return (dispatch, getState) => {
    dispatch({
      type: CHECK_TOKEN,
    });

    // If there's no token in state, just invalidate it
    if (getState().auth.token === null) {
      return dispatch({
        type: CHECK_TOKEN_INVALID,
      });
    }

    /**
     * Why are you not using the req-instance here?
     * A good question. For some reason the req-instance returns a nicely formatted
     * human-friendly error message when something goes wrong and not the actual response.
     * I tried multiple different approaches with no luck so i figured that since this
     * solves the issue i'll leave it here.
     * This way we can programmatically check why the request failed in the catch block.
     * If the request failed due to anything else than the token being invalid, we will
     * let the user keep the token (service unavailable, user offline, etc.). And check
     * it next time. Worst that can happen is a user having access to date-less pages.
     */

    let apiUrl = getState().appConfig.apiUrl;
    let token = getState().auth.token;

    axios
      .post(`${apiUrl}auth/verify-token`, {}, { headers: { Authorization: `bearer ${token}` } })
      .then((res) => {
        // Token is ok. Get language and pages
        dispatch(getLanguage());
        dispatch(getPages());

        // After 1 second renew token
        setTimeout(() => dispatch(renewToken()), 1000);

        // Dispatch action
        return dispatch({
          type: CHECK_TOKEN_VALID,
        });
      })
      .catch((err) => {
        // Token is invalid, it's been tampered with or we've changed our secret
        if (err.response && err.response.status === 403) {
          return dispatch({
            type: CHECK_TOKEN_INVALID,
          });

          // Service to check token isn't available right now for whatever reason
        } else {
          return dispatch({
            type: CHECK_TOKEN_SERVICE_UNAVAILABLE,
          });
        }
      });
  };
}

export function renewToken(callback) {
  return (dispatch, getState) => {
    // Get token from state

    req()
      .post("/auth/renew-token")
      .then(({ data }) => {
        // Token renewed
        dispatch({
          type: RENEW_TOKEN_SUCCESS,
          payload: data,
        });
        if (callback) callback();
      })
      .catch(() => {
        // Token could no be renewed
        dispatch({
          type: RENEW_TOKEN_FAILURE,
        });
        if (callback) callback();
      });

    // req().post("statistics/app/visits");
  };
}

export function renewPassword(phone) {
  return (dispatch, getState) => {
    let state = getState();
    req()
      .post("auth/renew-password", { phone, applicationId: state.appConfig.appId })
      .then(({ data }) => {
        return dispatch({
          type: RENEW_PASSWORD_SUCCESS,
        });
      })
      .catch(() => {
        // Call toaster if error happened
        return dispatch({
          type: RENEW_PASSWORD_FAILED,
        });
      });
  };
}

export function renewPasswordHandled() {
  return {
    type: RENEW_PASSWORD_HANDLED,
  };
}
